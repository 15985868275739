@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700&display=swap);
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafaff;
  font-weight: 400;
}

main {
  background: #151418;
  background-image: url(https://raw.githubusercontent.com/gurupawar/portfolio/main/assets/blob-haikei.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
  background-color: transparent;
}

.art_images{
  height: auto;
  width: auto;

}

ul,
ol {
  list-style: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

img{
  display: block;
  max-width: 100%;
}

.btn {
  border: 2px solid rgb(57, 134, 250);
  padding: 10px 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 400;
  outline: none;
}

.PrimaryBtn {
  background: #00334d;
  color: #fff;
}

.PrimaryBtn:hover {
  background: #fff;
  color: #010606;
}

.SecondarBtn:hover {
  background: #00334d;
  color: #fff;
}

.btn2 {
  margin-right: 1rem;
}

.AboutBio{
  font-size: 20px;
}

.fewButton{
  width: 130px;
  text-align: center;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  letter-spacing: 5px;
  color: white;
  margin-left: 70px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.fewButton:hover{
  width: 130px;
  background-color: white;
  color: black;
  text-align: center;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  letter-spacing: 5px;
  transition: color 0.5s ease;
  transition: background-color 0.5s ease;
  margin-left: 70px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.giochino{
  background-color: black;
  padding: 10px;
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #000000; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.indications{
  text-align: center;
  font-size: 25px;
  color: white;
  font-family: sans-serif;
  font-weight: bold;
  letter-spacing: -3px;
  margin: -10px;
}

.runTheModel{
  font-size: 30px;
  letter-spacing: 15px;
  color: white;
}

.runTheModel:hover{
  border-bottom-style: solid;
  border-bottom-width: 1px;
  -webkit-animation: border 1s;
          animation: border 1s
}

.resume{
  color: blue;
}

.Container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}

.BigCard {
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-right: 2rem;
  padding-left: 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 10px;
  /* box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05); */
}

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transform: translateX(-100px);
          transform: translateX(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  -webkit-transform: translateX(-100px);
          transform: translateX(-100px);
}

.AboutBio,
.tagline2 {
  text-align: center;
  max-width: 650px;
  margin: 0 auto;
  color: #151418;
  line-height: 1.6;
}

.tagline2 {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.SectionTitle {
  font-size: 28px;
  color: #151418;
  font-weight: 700;
  margin-bottom: 3.5rem;
}
.ProjectWrapper {
  margin-top: -1rem;
}

.modal_content{
  padding: 10px;
}

.proj_title{
  font-weight: bold;
  font-size: 30px;
}

.proj_subtitle{
  margin-top: 1px;
}

.info{
  border-width: 1px;
  border-style: solid;
  border-color: black;
  border-radius: 22px;
  margin: 5px;
  background-image: linear-gradient(to bottom right,#ffe6cc, #cce6ff);
}

.input{
  font-weight: bold;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.keywords{
  font-style: italic
}

.important1{
  font-style:bold;
  color: green;
}

.block_gen{
  background: #00334d;
  margin: 1px;
  padding: 5px;
  border-radius: 17px;
  color: white;
}

/* ------------------------------------ Media for Container */

@media (max-width: 600px){
  .giochino{
    display: none;
  }
}

@media (min-width: 76px) {
  .Container {
    max-width: 540px;
  }
  .block_gen{
    background-color: #00334d;
    margin: 1px;
  }
  .paragraph{
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .Container {
    max-width: 720px;
  }
  .ProjectWrapper {
    margin-top: -5rem;
  }
  .block_gen{
    background-color: #00334d;
    margin: 2px;
  }
  .paragraph{
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .Container {
    max-width: 960px;
  }
  .block_gen{
    background-color: #00334d;
    margin: 3px;
  }
  .paragraph{
    font-size: 22px;
  }
}
@media (min-width: 1200px) {
  .Container {
    max-width: 1000px;
  }
  .block_gen{
    background-color: #00334d;
    margin: 5px;
  }
  .paragraph{
    font-size: 26px;
  }
}


